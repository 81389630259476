<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Tambah Pengguna</h3>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="mb-6 p-5">
          <div>
            <br />
            <form>
              <div class="form-group row">
                <label class="col-lg-4">Nama</label>
                <div class="col-lg-8">
                  <input type="text" v-model="name" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Email</label>
                <div class="col-lg-8">
                  <input type="text" v-model="email" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">No. HP</label>
                <div class="col-lg-8">
                  <input type="text" v-model="no_hp" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Username</label>
                <div class="col-lg-8">
                  <input type="text" v-model="username" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Password</label>
                <div class="col-lg-8">
                  <input
                    type="password"
                    v-model="password"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Role</label>
                <div class="col-lg-8">
                  <s-select
                    label="name"
                    v-model="selectedrole"
                    :options="roles"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Alamat</label>
                <div class="col-lg-8">
                  <textarea type="text" v-model="alamat" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Bidang Ilmu</label>
                <div class="col-lg-8">
                  <input
                    type="text"
                    v-model="bidang_ilmu"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Pendidikan Terakhir</label>
                <div class="col-lg-8">
                  <input
                    type="text"
                    v-model="pendidikan_terakhir"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label"
                  >Tempat/Tanggal Lahir</label
                >
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          type="text"
                          v-model="tempat_lahir"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <b-form-datepicker
                          v-model="tanggal_lahir"
                          id="example-datepicker"
                          class="mb-2"
                        ></b-form-datepicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label"
                  >Upload File Ijazah</label
                >
                <div class="col-lg-8">
                  <b-form-file
                    v-model="ijazah"
                    class="mt-3"
                    plain
                  ></b-form-file>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label">ID PTSP / IDKL</label>
                <div class="col-lg-8">
                  <input type="text" v-model="id_ptsp" class="form-control" />
                </div>
              </div>
              <div class="card-toolbar float-right">
                <button
                  type="reset"
                  class="btn btn-success mr-2"
                  @click="tambah()"
                  ref="kt_save_changes"
                  style="background-color: #3699ff"
                >
                  Tambah
                </button>
                <button
                  type="reset"
                  class="btn btn-secondary"
                  @click="cancel()"
                >
                  Kembali
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import vSelect from "vue-select";
import Swal from "sweetalert2";

Vue.component("s-select", vSelect);

export default {
  name: "tambahPengguna",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      roles: [],
      name: "",
      alamat: "",
      bidang_ilmu: "",
      username: "",
      ijazah: "",
      kode_wilayah: "",
      no_hp: "",
      password: "",
      pendidikan_terakhir: "",
      tanggal_lahir: "",
      tempat_lahir: "",
      email: "",
      selectedrole: "",
      id_ptsp: "",
      bagian: "",
    };
  },
  components: {
    vSelect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Tambah Pengguna" }]);
    this.getrole();
  },
  methods: {
    getrole() {
      axios
        .get(this.url + "/role", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.roles = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    tambah() {
      let formData = new FormData();

      formData.append("name", this.name);
      formData.append("username", this.username);
      if (this.email !== null && this.email !== "") {
        formData.append("email", this.email);
      }
      formData.append("password", this.password);
      formData.append("tanggal_lahir", this.tanggal_lahir);
      formData.append("tempat_lahir", this.tempat_lahir);
      formData.append("ijazah", this.ijazah);
      formData.append("id_role", this.selectedrole.id);
      formData.append("name_role", this.selectedrole.name);
      formData.append("pendidikan_terakhir", this.pendidikan_terakhir);
      formData.append("no_hp", this.no_hp);
      formData.append("alamat", this.alamat);
      formData.append("bidang_ilmu", this.bidang_ilmu);
      formData.append("bagian", this.bagian);
      if (this.id_ptsp !== null && this.id_ptsp !== "") {
        formData.append("id_ptsp", this.id_ptsp);
      }

      axios
        .post(this.url + "/users", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            xth: this.token,
          },
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Pengguna Berhasil Ditambah!",
          }).then(() => {
            this.$router.push({ name: "User" });
          });
          console.log("User added successfully", response.data);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Gagal Meambahkan user. Please try again.",
            footer: error,
          });
          console.log("Error adding user", error);
        });
    },
    cancel() {
      this.$router.push("/users");
    },
  },
};
</script>